.logo-container {
  z-index: 0;
  width: 400px;
  height: 600px;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;
  opacity: 1;

  .solid-logo {
      position:absolute;
      top: auto;
      right: auto;
      bottom: auto;
      left: 0;
      margin: auto;
      width: 100%;
      opacity: 1;
      transform: rotateZ(30deg) !important;
      z-index: 1;
  }
}
